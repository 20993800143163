import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllAcademyDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`AcademyDiscount/GetAll`);
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

class DeleteAcademyDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`AcademyDiscount/Delete`);
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}


class UpdateAcademyDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('AcademyDiscount/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateAcademyDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('AcademyDiscount/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}
class GetVendorAcademyPrices extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('AcademyPrice/GetVendorAcademyPrices');
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}
export {
    GetAllAcademyDiscount,
    DeleteAcademyDiscount,
    UpdateAcademyDiscount,
    CreateAcademyDiscount,
    GetVendorAcademyPrices
};
