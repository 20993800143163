import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllAcademyCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('AcademyCategory/GetAllForAdmin')
    }
    setParams(data) {
        super.setRequestParam(data)
    }
}
class GetAllAcademyCategoryForAll extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`AcademyCategory/GetAll`);
    }

}
class DeleteAcademyCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`AcademyCategory/Delete?id=${data.id}`);
    }
}

class UpdateAcademyCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('AcademyCategory/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateAcademyCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('AcademyCategory/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

export {
    GetAllAcademyCategory,
    GetAllAcademyCategoryForAll,
    DeleteAcademyCategory,
    UpdateAcademyCategory,
    CreateAcademyCategory
};
